<template>
    <component :is="block.component" :data="data[block.dataId]" > 
        <block v-for="(item, index) in block.children" :key="index" :block="item" :data="data"></block>
    </component>
</template>
<script>

export default {
    name: 'Block',
    props: {
        block: {},
        data: {}
    },
    components: {
		Banner:() => import( '../blocks/Banner.vue' ),
		Calendar:() => import( '../blocks/Calendar.vue' ),
		CardSlider:() => import( '../blocks/CardSlider.vue' ),
		Cards:() => import( '../blocks/Cards.vue' ),
		CookieBanner:() => import( '../blocks/CookieBanner.vue' ),
		FooterMenu:() => import( '../blocks/FooterMenu.vue' ),
		HeaderMenu:() => import( '../blocks/HeaderMenu.vue' ),
		IconsCards:() => import( '../blocks/IconsCards.vue' ),
		ImageBlock:() => import( '../blocks/ImageBlock.vue' ),
		TextBlock:() => import( '../blocks/TextBlock.vue' ),
		TextWithImage:() => import( '../blocks/TextWithImage.vue' ),
		TextWithNumber:() => import( '../blocks/TextWithNumber.vue' ),
		Space:() => import('../views/Space.vue'),
		SpaceSingleMonetizationBundle:() => import('../views/MonetizationBundle/SpaceSingleMonetizationBundle.vue')
		/*--mounted--*/
    },
    methods: {}
}
</script>

<style></style>