<template>
    <div class="ssr" v-show="loaded">
        <div class="bottom-menu">
            <a v-for="(menuItem, index) in bottomLinks" :key="`sidebar-link-${index}`" :href="menuItem.url">
                <img v-if="menuItem.image" :src="menuItem.image" :alt="menuItem.title" />
                <font-awesome-icon v-else-if="menuItem.icon" :icon="menuItem.icon" />
                <span>
                    {{ menuItem.title }}
                </span>
            </a>
            <font-awesome-icon
                icon="custom-menu"
                class="open-menu"
                @click="sidebarOpened = true"
            />
        </div>
        <div :class="{ sidebar: true, opened: sidebarOpened ? true : false }">
            <div class="logo" :href="'#'">
                <a :href="'/'">
                    <img :src="require('../assets/logo-mobile-header.svg')" />
                    <img class="small" :src="require('../assets/re-logo.svg')" />
                </a>
                <svg class="sidebar-close" @click="sidebarOpened = false" xmlns="http://www.w3.org/2000/svg" width="32.717" height="32.681" viewBox="0 0 32.717 32.681"><script xmlns=""/>
                    <g opacity="0.3">
                        <path data-name="Close" d="M18.74,16.5,32.186,3.125a1.619,1.619,0,0,0,0-2.308,1.658,1.658,0,0,0-2.331,0L16.421,14.179,2.862.618A1.65,1.65,0,0,0,.531,2.956L14.08,16.506.482,30.029a1.621,1.621,0,0,0,0,2.308,1.658,1.658,0,0,0,2.331,0L16.4,18.825,29.9,32.332A1.65,1.65,0,0,0,32.235,30Z" transform="translate(0 -0.135)"/>
                    </g>
                </svg>
            </div>
            <div class="links">
                <a v-for="(menuItem, index) in headerMenu" :key="`sidebar-link-${index}`" :href="menuItem.url">
                    <img v-if="menuItem.image" :src="menuItem.image" :alt="menuItem.title" />
                    <font-awesome-icon v-else-if="menuItem.icon" :icon="menuItem.icon" />
                    <span>
                        {{ menuItem.title }}
                    </span>
                </a>
            </div>
            <div class="auth">
                <div v-if="loggedIn" class="logged">
                    <div class="avatar">
                        <font-awesome-icon
                            icon="user-circle"
                            size="3x"
                            class="user-avatar"
                        />
                    </div>
                    <div class="info">
                        <span class="user-name">
                            User1
                        </span>
                        <a class="profile-link" href="#">
                            Profile settings
                        </a>
                    </div>
                    <div class="user-logout">
                        <font-awesome-icon icon="sign-out-alt" />
                    </div>
                </div>

                <div v-show="spaceSlug && spaceSlug.length" class="sign-in">
                    <span>
                        {{ labels.login }}
                    </span>
                    <form> 
                        <input name="username" type="text" :placeholder="labels.email" v-model="username" />
                        <input name="password" type="password" :placeholder="labels.password" v-model="password" />
                        <button @click="ssrLogin" type="button">
                            {{ labels.sign_in }}
                        </button>
                        <a href="/lost-password">
                            {{ labels.forgot_password }}
                        </a>
                    </form>
                </div>
            </div>
            <div class="sidebar-toggler" @click="sidebarOpened = !sidebarOpened">
                <font-awesome-icon :icon="toggleIcon" class="toggle-icon" />

                <span v-if="sidebarOpened">
                    {{ labels.hide_menu }}
                </span>
            </div>
        </div>
        <div :class="{ ['generic-blocks-container']: true, ['sidebar-opened']: sidebarOpened ? true : false }">
            <div class="generic-block-min-height">
                <block v-for="(item, index) in structure" :key="index" :block="item" :data="data"></block>
            </div>
            <div class="footer-inner">
                <div class="row">
                    <div class="col-6 col-md-3" v-for="(mi, k) in footerMenu" :key="k">
                        <a class="info-item" :href="mi.url">{{mi.title}}</a>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Block from '../components/Block.vue';
import { library } from "@fortawesome/fontawesome-svg-core";
import pss from '../services/persistent-state-storage.js';

import {
  faChevronLeft,
  faChevronRight,
  faUserCircle,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";

import {
  customHome,
  customTimes,
  customNoteList,
  customLayout,
  customBarChart,
  customImage,
  customBookmark,
  customHandPackages,
  customMenu,
  customMonitor,
  customUser,
  customCog,
} from "../assets/icons";

library.add(
  faChevronLeft,
  faChevronRight,
  customHome,
  customTimes,
  customNoteList,
  customLayout,
  customBarChart,
  customImage,
  customBookmark,
  customHandPackages,
  customMenu,
  customMonitor,
  customUser,
  customCog,
  faUserCircle,
  faSignOutAlt
);


export default {
    name: 'Generic',
    props:{
        data:{},
        structure:{},
        menu:{}
    },
    components: {
        Block
    },
    data() {
        return {
            loggedIn: false,
            sidebarOpened: false,
            labels: {
                hide_menu: 'Hide menu',
                login: 'Login',
                email: 'E-Mail',
                password: 'Password',
                sign_in: 'Sign in',
                forgot_password: 'Forgot password?'
            },
            username: '',
            password: '',
            loaded:false
        }
    },
    computed: {
        spaceSlug() {
            if(typeof window === 'undefined') return '';

 
            const splited = window.location.href.split('@');

            if (splited.length == 2) return splited[1]||'';
            
            return '';
        },
        headerMenu() {
            return this.menu.header;
        },
        footerMenu() {
            return this.menu.footer;
        },
        bottomLinks() {
            let result = {};

            const menuKeys = Object.keys(this.menu);
            
            if (menuKeys.length) {
                let bototmMenuKey = 0;
                menuKeys.forEach(menuKey => {
                    if (this.menu[menuKey]?.icon || this.menu[menuKey]?.image) result[bototmMenuKey++] = this.menu[menuKey];
                });
            }

            return result;
        },
        toggleIcon() {
            if (!this.sidebarOpened) return 'chevron-right';

            return 'chevron-left';
        }
    },
    methods: {
        ssrLogin() {
            axios.post('/login', {
                username: this.username,
                password: this.password,
                logToSpace: this.spaceSlug
            }, { baseURL: '/api/p' }).then(res => {
                console.log('Login res:', res)
                pss.set('userIsAuthenticated', true, {
                    isObject: true
                })
                pss.set('userBaseUrl', res.data.baseUrl)
                pss.set('userRole', res.data.role)
                pss.set('username', res.data.username)
                pss.set('userFullName', res.data.name)
                pss.set('userid', res.data._id)
                pss.set('loggedToSpace', this.spaceSlug)
                location.reload();
            }).catch(err => {
                console.log(err)
            });
        },
        ssrLogout() {
            axios.get('/logout', { baseURL: '/api/p' }).then( () => {

            }).catch(err => {
                done(err);
            })
        }
    },
    mounted(){
        setTimeout(()=>{
            this.loaded = true
        },100)
    }
}
</script>

<style lang="scss" scoped>

.generic-block-min-height{
    min-height:100vh;
}
    @mixin sidebar-padding {
        padding: 0 15px;

        @media (min-width: 1200px) {
            padding: 0 30px;
        }

        @media (min-width: 1600px) {
            padding: 0 50px;
        }
    }

    @mixin sidebar-element-mb {
        margin-bottom: 15px;

        @media (min-width: 1200px) {
            margin-bottom: 30px;
        }

        @media (min-width: 1600px) {
            margin-bottom: 25px;
        }
    }
    .footer-inner {
        padding: 30px 60px;
        background: black;
        *, a {
            color: white !important;
            text-decoration:none;
        }
    }
    .ssr {
        display: flex;
        position: relative;
        overflow: hidden;

        .generic-blocks-container {
            width: 100%;
            overflow-y: auto;
            height: 100vh;
            margin-left: auto;

            @media (min-width: 768px) {
                width: calc( 100% - 50px );
            }

            @media (min-width: 1200px) {
                width: calc( 100% - 310px );
            }
        }

        .sidebar {
            max-height: 100vh;
            height: 100vh;
            position: absolute;
            width: 0;
            background-color: #fff;
            padding-top: 15px;
            padding-bottom: 15px;
            z-index: 4;
            transition: all 300ms ease;
            right: 0;
            overflow-y: auto;
            display: flex;
            flex-direction: column;

            &-toggler {
                display: none;
                width: 0.65em;

                @media (max-width: 1199.98px) {
                    @include sidebar-padding;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    fill: #b0b0b0;
                    color: #b0b0b0;

                    span {
                        margin-left: 15px;
                    }
                }
            }

            > * {
                width: 100%;
            }

            @media (min-width: 768px) {
                width: 50px;
                left: 0;
                right: unset;
            }

            @media (min-width: 1200px) {
                width: 310px;
                padding-top: 30px;
                padding-bottom: 30px;
            }

            @media (min-width: 1600px) {
                width: 310px;
                padding-top: 50px;
                padding-bottom: 50px;
            }

            .logo {
                @include sidebar-padding;
                @include sidebar-element-mb;

                display: block;
                position: relative;

                > a img,
                > a svg {
                    max-width: 140px;
                    height: auto;
                    object-fit: contain;
                    object-position: left;
                    display: none;

                    @media (min-width: 1200px) {
                        display: block;
                    }
                }

                .small {
                    display: block;
                    max-width: 20px;

                    @media (min-width: 1200px) {
                        display: none;
                    }
                }

                .sidebar-close {
                    display: none;

                    @media (max-width: 767.98px) {
                        right: 15px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        display: block;
                        position: absolute;
                    }
                }
            }

            .links {
                @include sidebar-padding;
                @include sidebar-element-mb;

                a,
                span {
                    color: #292929;
                    display: flex;
                    align-items: center;
                    text-decoration: none;

                    &:hover {
                        text-decoration: none;
                    }

                    &:not(:last-child) {
                        margin-bottom: 7.5px;
                    }

                    @media (min-width: 1200px) {
                        &:not(:last-child) {
                            margin-bottom: 12.5px;
                        }
                    }

                    @media (min-width: 1600px) {
                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }
                    }
                    
                    img,
                    svg {
                        width: 1em;
                        height: 1.5em;
                        object-fit: contain;
                        object-position: left;
                    }

                    .no-icon {
                        width: 1em;
                        height: 1.5em;
                    }

                    span {
                        color: #292929;
                        display: none;

                        @media (min-width: 1200px) {
                            display: block;
                            margin-left: 0.5rem;
                        }
                    }
                }

                background-color: #f2f2f2;
                padding-top: 15px;
                padding-bottom: 15px;

                @media (min-width: 1200px) {
                    padding-top: 30px;
                    padding-bottom: 30px;
                }

                @media (min-width: 1600px) {
                    padding-top: 25px;
                    padding-bottom: 25px;
                }
            }

            .auth {
                @include sidebar-padding;

                display: none;

                @media (min-width: 1200px) {
                    display: block;
                }

                .sign-in {
                    width: 100%;

                    h5 {
                        margin-bottom: 1rem;
                    }

                    form {
                        width: 100%;
                        input {
                            width: 100%;
                            margin-bottom: 0.8rem;
                            padding: 0.25rem 0rem;
                            border: 0px;
                            border-bottom: 1px solid #f2f2f2;

                            &:focus,
                            &:focus-visible,
                            &:focus-within,
                            &:target {
                                outline: none;
                            }
                        }

                        button {
                            background-color: #6c41e2;
                            color: #fff;
                            border: 2px solid #6c41e2;
                            border-radius: 0.25rem;
                            transition: all 0.3s ease;
                            width: 100%;
                            padding: 0.25rem;
                            font-size: 1.2rem;
                            box-shadow: 0 2px 11px -1px rgba(0, 0, 0, .2196078431372549);

                            &:hover {
                                color: #6c41e2;
                                background-color: #fff;
                            }

                            &:focus,
                            &:focus-visible,
                            &:focus-within,
                            &:target {
                                outline: none;
                            }
                        }

                        > a {
                            display: block;
                            width: 100%;
                            margin-top: 1.5rem;
                            text-align: center;
                            color: #292929;
                            opacity: 0.3;
                        }
                    }
                }

                .logged {
                    display: flex;
                    align-items: center;

                    > *:not(:last-child) {
                        margin-right: 7.5px;

                        @media (min-width: 1200px) {
                            margin-right: 15px;
                        }

                        @media (min-width: 1600px) {
                            margin-right: 25px;
                        }
                    }

                    .avatar {
                        img,
                        svg {
                            width: 1em;
                            height: auto;
                            object-fit: cover;
                            object-position: center;
                        }

                        svg {
                            fill: #d3d3d3;
                            color: #d3d3d3;
                        }
                    }

                    .info {
                        display: flex;
                        flex-direction: column;

                        span,
                        a {
                            display: block;
                        }

                        span {
                            font-size: 16px;
                            font-weight: 600;
                            font-family: Oswald, sans-serif;
                            color: #000;
                            line-height: 1.5;
                            text-align: left;
                            margin-bottom: 0.2rem;
                        }

                        a {
                            color: #7f7f7f;
                            font-size: 10px;
                            text-decoration: none;
                            background-color: transparent;
                            text-decoration: none;

                            &:hover {
                                text-decoration: none;
                            }
                        }
                    }

                    .user-logout {
                        font-size: 22px;
                        cursor: pointer;

                        svg {
                            color: #6c41e2;
                        }
                    }
                }
            }

            &.opened {
                @media (max-width: 1199.98px) {
                    width: 310px;

                    .sidebar-toggler {
                        justify-content: unset;

                        svg {
                            margin-top: 0.1rem;
                        }
                    }

                    .logo {
                        > a img,
                        > a svg {
                            display: block;
                        }
                        
                        .small {
                            display: none;
                        }
                    }

                    .links {
                        a,
                        span {
                            span {
                                display: block;
                                margin-left: 0.5rem;
                            }
                        }
                    }

                    .auth {
                        @include sidebar-element-mb;

                        display: block;
                    }
                }
            }

            @media (max-width: 767.98px) {
                pointer-events: none;
                opacity: 0;

                .logo,
                .auth,
                .links,
                .sidebar-toggler {
                    display: none;
                }

                &.opened {
                    opacity: 1;
                    pointer-events: unset;

                    .logo,
                    .auth,
                    .links {
                        display: block;
                    }

                    .auth {
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .bottom-menu {
            position: absolute;
            left: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            background-color: #fff;
            box-shadow: 0 -3px 15px -6px rgba(0,0,0,.1803921568627451);
            padding: 15px;
            display: none;

            @media (max-width: 767.98px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            > a,
            > span {
                color: #292929;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-decoration: none;
                font-size: 0.7rem;
                margin-right: 1rem;

                &:hover {
                    text-decoration: none;
                }
                
                img,
                svg {
                    width: 1em;
                    height: 1.5em;
                    object-fit: contain;
                    object-position: left;
                }

                span {
                    color: #292929;
                    display: block;
                    line-height: 1;
                }
            }

            > svg {
                font-size: 1.4rem;
            }
        }
    }
</style>
