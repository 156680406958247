import {Store} from 'vuex';
import * as getters from './getters';
//import user from "./user";
const createStore = (state) => {
    return new Store({
        state,
        getters,
        strict: process.env.NODE_ENV !== 'production',
        modules: {
            //user
        }
    });
};

export default createStore;