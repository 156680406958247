'use strict'

export default {
    set(key,value,option={}){
        let setValue = value
        if(option.isObject) setValue = JSON.stringify(value);
        localStorage.setItem(key,setValue);
        return value;
    },
    get(key,option={}){
        let returnValue = localStorage.getItem(key)

        if((option.default || option.default === false) && returnValue == null){
            returnValue = option.default;
            if(option.saveInit) this.set(false, key,returnValue,option);
        }
        

        if(option.isObject) returnValue = JSON.parse(returnValue);
        return returnValue;
    },
    clear(){
        localStorage.clear()
    }
}