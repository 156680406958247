import Vue from 'vue';
import Meta from 'vue-meta'
import App from './ssr-entry.vue';
import Vuex from 'vuex';
import createStore from './store';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueRellax from 'vue-rellax'


//import { BContainer, BRow, BCol, BButton, BCard, BCardText, BCarousel, BCarouselSlide } from 'bootstrap-vue';
//Vue.component('b-container', BContainer);
//Vue.component('b-row', BRow);
//Vue.component('b-col', BCol);
//Vue.component('b-button', BButton);
//Vue.component('b-card', BCard);
//Vue.component('b-card-text', BCardText);
//Vue.component('b-carousel', BCarousel);
//Vue.component('b-carousel-slide', BCarouselSlide);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Vuex);
Vue.use(Meta, {
    ssrAppId: 1 // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
});
Vue.use( VueRellax );


export const createApp = (context) =>  {

    const store = createStore(context.state);

    const app = new Vue({
        store,
        render: h => h(App)
    });
    
    return {app, store};
};